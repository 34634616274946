<template>
  <!-- Footer -->
  <footer id='footer' class='mt-9 footer border'>
    <b-container class="text-center text-md-left">
      <b-row class="text-center text-md-left ">
        <b-col md="3" >
          <h6 class="text-uppercase mb-4 font-weight-bold">Suport</h6>
          <p> <router-link class="text-dark" to="/status">Statusul Servarelor</router-link></p>
          <p> <router-link class="text-dark" to="/documentation">Ghid de instalare</router-link></p>
          <p> <router-link class="text-dark" to="/faq">Intrebari frecvente</router-link></p>
   
        </b-col>
        <hr class="w-100 clearfix d-md-none" />
        <b-col md="3" >
          <b-icon class='fav-icon' icon="facebook" aria-hidden="true"></b-icon>
          <b-icon class='fav-icon ml-5' icon="twitter" aria-hidden="true"></b-icon>
        </b-col>
        <hr class="w-100 clearfix d-md-none" />
        <b-col md="3" >
          <h6 class="text-uppercase mb-4 font-weight-bold">Terms of services</h6>
          <p><a >Disclaimer</a></p>
          <p><a >Cookies</a></p>
          <p><a >Spam Policy</a></p>
          <p><a >GDPR</a></p>
          <p><a >Personal data policy</a></p>
        </b-col>
        <hr class="w-100 clearfix d-md-none" />
        <b-col md="3" >
          <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6>
          <p> suport@smtp.com </p>
          <p> Skype: smtpsuport</p>
          <p> (+4) 0723 324 423</p>
          <p> Program de lucru : <br> Luni-Vineri / 10:00 - 15:00</p>
        </b-col>
      </b-row>
      <hr />
      <b-row >
          <p class="text-center text-md-left grey-text">&copy; 2020 Smtp.com </p>
      </b-row>
    </b-container>
  </footer>
  <!-- Footer -->
</template>

<script>
export default {
  name: 'Footer',
  computed: {
  },

  data () {
    return {
    }
  },

  methods: {

  }
}
</script>

<style scoped>
  .row{
    margin-top: 2rem;
  }
  .fav-icon{
    width: 2rem;
    height: 2rem;
  }
  .footer{
    /* position:absolute;
    width:100%;
    bottom:0px; */
  }
</style>