<template>
    <!-- <b-container> -->
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-container>                
          <b-navbar-brand to='/'>Smtp</b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse"></b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/prices">Preturi</b-nav-item>
              <b-nav-item-dropdown text="Suport">
                <b-dropdown-item to="/status">Status Server</b-dropdown-item>
                <b-dropdown-item to="/documentation">Documentatie</b-dropdown-item>
                <b-dropdown-item to="/faq">Intrebari frecvente</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item to="/contact">Contact</b-nav-item>
              <b-nav-item-dropdown text="Log in">
                <b-container>
                <b-dropdown-form>
                  <b-form-group label="Email" label-for="dropdown-form-email" @submit.stop.prevent>
                    <b-form-input
                      id="dropdown-form-email"
                      size="sm"
                      placeholder="email@example.com"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Password" label-for="dropdown-form-password">
                    <b-form-input
                      id="dropdown-form-password"
                      type="password"
                      size="sm"
                      placeholder="Password"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-checkbox class="mb-3">Remember me</b-form-checkbox>
                  <b-button variant="primary" size="sm" @click="onClick">Sign In</b-button>
                </b-dropdown-form>
                 
                </b-container>
                 <b-dropdown-divider></b-dropdown-divider>
                 <b-dropdown-item-button>Sign up</b-dropdown-item-button>
                 <b-dropdown-item-button>Forgot Password?</b-dropdown-item-button>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>

    <!-- </b-container> -->
</template>

<script>
export default {
  name: 'Navbar',
  computed: {
  },

  data () {
    return {
    }
  },

  methods: {
     onClick() {
        // Close the menu and (by passing true) return focus to the toggle button
        this.$refs.dropdown.hide(true)
      }
  }
}
</script>
