import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Prices from '../views/Prices.vue'
import Status from '../views/Status.vue'
import Documentation from '../views/Documentation.vue'
import FAQ from '../views/FAQ.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/prices',
    name: 'Prices',
    component: Prices
  },
  {
    path: '/status',
    name: 'StatusServer',
    component: Status
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
]

const router = new VueRouter({
  routes
})

export default router
